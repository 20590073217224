import React, { useEffect } from "react";
import { encode, decode } from "js-base64";
import "./App.css";
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useFilePicker } from "use-file-picker";

function App() {
  const [yieldZone, setYieldZone] = React.useState(-1);
  const [season, setSeason] = React.useState(-1);
  const [moisture, setMoisture] = React.useState(-1);

  const allFieldsFilled = yieldZone !== -1 && season !== -1 && moisture !== -1;

  const bgColors = [
    "#deeaf6",
    "#bdd6ee",
    "#9cc2e5",
    "#79addd",
    "#5b9bd5",
    "#2e75b5",
    "#1e4e79",
    "#002060",
  ];
  const fgColors = [
    "black",
    "black",
    "black",
    "black",
    "black",
    "white",
    "white",
    "white",
  ];
  const variables = [
    "extractableNitrateBins",
    "extractableAmmoniumBins",
    "microbialBiomassBins",
    "respirationBins",
    "organicMatterBins",
    "phBins",
    "fieldWaterBins",
    "whcWaterBins",
  ];

  const [resp, setResp] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [openFileSelector, { filesContent, loading: fileLoading }] =
    useFilePicker({
      multiple: false,
    });
  const [didError, setDidError] = React.useState(false);

  const handleClick = async () => {
    openFileSelector();
  };

  const handleFetch = async () => {
    console.log("filesContent", filesContent);
    setLoading(true);
    setDidError(false);
    try {
      // Base64 encode the file content
      const content = filesContent[0].content;
      const base64 = encode(content);
      console.log(base64);
      // Post to http://localhost:5555/api/analyse with json body
      const response = await fetch("https://pes.arcanaeum.me/api/analyse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: base64,
          yield: yieldZone,
          season,
          moisture,
        }),
      });
      const data = await response.json();
      console.log("data", data);
      setResp(data);
    } catch (e) {
      console.log(e);
      setDidError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filesContent.length > 0) {
      console.log("filesContent", filesContent);
      void handleFetch();
    }
  }, [filesContent]);

  return (
    <div className="App">
      <Container>
        <FormControl as="fieldset">
          <FormLabel as="legend">Yield</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setYieldZone(-1);
              } else {
                setYieldZone(val);
              }
            }}
            placeholder={yieldZone === -1 ? "Select yield zone" : undefined}
          >
            <option value="0">Low</option>
            <option value="1">Medium</option>
            <option value="2">Reference</option>
          </Select>
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">Season</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setSeason(-1);
              } else {
                setSeason(val);
              }
            }}
            placeholder={season === -1 ? "Select season" : undefined}
          >
            <option value="0">Spring</option>
            <option value="1">Autumn</option>
            <option value="2">Summer</option>
            <option value="3">Winter</option>
          </Select>
          <FormHelperText>
            {season === 2 || season === 3
              ? "Summer and Winter results may be inaccurate due to lack of data"
              : ""}
          </FormHelperText>
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">Moisture</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setMoisture(-1);
              } else {
                setMoisture(val);
              }
            }}
            placeholder={moisture === -1 ? "Select moisture level" : undefined}
          >
            <option value="0">Dry</option>
            <option value="1">Moist</option>
            <option value="2">Wet</option>
          </Select>
        </FormControl>
        <br />
        <FormControl>
          <Tooltip label={!allFieldsFilled ? "Please fill all fields" : ""}>
            <Button
              isLoading={loading || fileLoading}
              onClick={handleClick}
              disabled={!allFieldsFilled}
            >
              Select File
            </Button>
          </Tooltip>
          {didError && (
            <FormHelperText color={"red"}>
              An error occurred. Please try again.
            </FormHelperText>
          )}
        </FormControl>
        <br />
      </Container>
      {resp && (
        <TableContainer>
          <Table size={"sm"}>
            <Thead>
              <Tr>
                <Th>&nbsp;</Th>
                <Th>Extractable Nitrate</Th>
                <Th>Extractable Ammonium</Th>
                <Th>Microbial Biomass</Th>
                <Th>Respiration</Th>
                <Th>Organic Matter</Th>
                <Th>pH</Th>
                <Th>Field Water %</Th>
                <Th>Whc Water %</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Index</Td>
                <Td
                  bgColor={bgColors[resp.extractableNitrate]}
                  color={fgColors[resp.extractableNitrate]}
                >
                  [{resp.extractableNitrate + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.extractableAmmonium]}
                  color={fgColors[resp.extractableAmmonium]}
                >
                  [{resp.extractableAmmonium + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.microbialBiomass]}
                  color={fgColors[resp.microbialBiomass]}
                >
                  [{resp.microbialBiomass + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.respiration]}
                  color={fgColors[resp.respiration]}
                >
                  [{resp.respiration + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.organicMatter]}
                  color={fgColors[resp.organicMatter]}
                >
                  [{resp.organicMatter + 1}]
                </Td>
                <Td bgColor={bgColors[resp.ph]} color={fgColors[resp.ph]}>
                  [{resp.ph + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.fieldWater]}
                  color={fgColors[resp.fieldWater]}
                >
                  [{resp.fieldWater + 1}]
                </Td>
                <Td
                  bgColor={bgColors[resp.whcWater]}
                  color={fgColors[resp.whcWater]}
                >
                  [{resp.whcWater + 1}]
                </Td>
              </Tr>
              <Tr>
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
              </Tr>
              <Tr>
                <Td>Key</Td>
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
              </Tr>
            </Tbody>
            <Tfoot>
              {bgColors.map((bgColor, i) => (
                <Tr key={`legend-${i}`} bgColor={bgColor} color={fgColors[i]}>
                  <Td>[{i + 1}]</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[0]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[0]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[1]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[1]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[2]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? `-${resp[variables[2]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[3]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[3]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[4]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[4]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[5]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[5]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[6]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[6]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                  <Td>{`${i === 0 ? "< " : ""}${i === 7 ? "> " : ""}${resp[
                    variables[7]
                  ][i === 0 ? 1 : i].toFixed(2)}${
                    i > 0 && i < 7
                      ? ` - ${resp[variables[7]][i + 1].toFixed(2)}`
                      : ""
                  }`}</Td>
                </Tr>
              ))}
            </Tfoot>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default App;
